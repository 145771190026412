import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

import Breadcrumbs from "@components/Breadcrumbs";
import TitleSection from "@components/TitleSection";
import Gallery from "@components/Gallery";
import Button from "@components/Button";
import ReservationTiles from "@components/ReservationTiles";
import ActualPromotions from "@components/ActualPromotions";
import Seo from "@components/Seo";

import ArrowIcon from "@icons/arrow.svg";

function OfferPage({ data, pageContext }) {
  const { seo } = data.seo_data.nodes[0];
  let { gallery_thumbnails, gallery_fullImage } = data;
  gallery_thumbnails = gallery_thumbnails?.nodes[0]?.pageGallery?.gallery;
  gallery_fullImage = gallery_fullImage?.nodes[0]?.pageGallery?.gallery;
  const reservation_items =
    data?.reservation_data?.nodes[0]?.pageHome?.reservation;

  const [numberPhotos, setNumberPhotos] = useState(6);

  const { slug, title, description, image, suboffers } = pageContext;
  const gatsby_featured_image = getImage(image?.gatsbyImage);

  const breadcrumbs_data = [{ name: title, href: `/${slug}` }];
  const { t } = useLocale();

  return (
    <>
      <Seo title={seo?.title} description={seo?.metaDesc} />
      <div className="offer-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <TitleSection title={title} image={gatsby_featured_image}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </TitleSection>

          {suboffers && (
            <section className="offer-page__suboffers">
              <h2>{t("Rodzaje zabiegów")}</h2>
              <h3>
                {t(
                  "Kliknij jeśli chcesz dowiedzieć się więcej o danym zabiegu"
                )}
              </h3>
              <ul className="offer-page__suboffers-list">
                {suboffers?.map((item) => (
                  <li
                    className="offer-page__suboffers-item"
                    key={item?.suboffersItem?.slug}
                  >
                    <Link to={`/oferta/${item?.suboffersItem?.slug}`}>
                      {item?.suboffersItem?.title}
                      <ArrowIcon width={13} height={10} className="arrow" />
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          )}
        </div>

        {gallery_thumbnails?.length > 0 && (
          <section className="offer-page__gallery">
            <div className="container">
              <h2>Galeria</h2>
              <Gallery
                limit={numberPhotos}
                data={{
                  thumbnails: gallery_thumbnails,
                  fullImages: gallery_fullImage,
                }}
              />
              {gallery_thumbnails?.length > numberPhotos && (
                <Button
                  variant="white"
                  onClick={() => setNumberPhotos((prev) => prev + 6)}
                >
                  Pokaż więcej zdjęć
                </Button>
              )}
            </div>
          </section>
        )}

        <section className="offer-page__reservation">
          <div className="container">
            <ReservationTiles items={reservation_items} />
          </div>
        </section>

        <section className="offer-page__promotions">
          <ActualPromotions />
        </section>
      </div>
    </>
  );
}

export default OfferPage;

export const query = graphql`
  query ($filtr: String!, $title: String!) {
    gallery_thumbnails: allWpImage(
      filter: { title: { regex: $filtr } }
      limit: 6
    ) {
      nodes {
        pageGallery {
          gallery {
            sourceUrl
          }
          category
        }
      }
    }
    gallery_fullImage: allWpImage(
      filter: { title: { regex: $filtr } }
      limit: 6
    ) {
      nodes {
        pageGallery {
          gallery {
            sourceUrl
          }
          category
        }
      }
    }
    reservation_data: allWpPage(filter: { title: { eq: "Strona główna" } }) {
      nodes {
        pageHome {
          reservation {
            title
            description
            link
            image {
              gatsbyImage(width: 546)
            }
          }
        }
      }
    }
    seo_data: allWpPage(filter: { title: { eq: $title } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
